<template>
  <v-container fluid class="pa-0" style="height: 100%;">
    <v-btn
      class="pl-2 pr-2 pa-0 pt-0 d-none d-sm-flex d-sm-none d-md-flex"
      style="position: absolute; z-index: 1; right: 190px; top: 15px;"
      @click="viewType()"
      color="primary"
      elevation="2"
    >
      <v-icon left>
        mdi-television
      </v-icon>
      <h6>
        DASHBOARD VIEW
      </h6>
    </v-btn>

    <v-card
      class="pa-0 pt-2 d-none d-sm-flex d-sm-none d-md-flex"
      style="position: absolute; z-index: 1; right: 10px; bottom: 80px;"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-card-text v-bind="attrs" v-on="on" class="pa-1">
            <v-switch
              class="mt-0"
              style="height: 40px;"
              v-model="enableAutoFocus"
            ></v-switch>
          </v-card-text>
        </template>
        <span>Auto-Focus on Vehicles</span>
      </v-tooltip>
    </v-card>

    <!-- This the side panel -->
    <div class="row" ref="mapPage" style="margin: 0 auto;">
      <div
        style="height: 100px; width: 20%; position: absolute; z-index: 1; left: 0px; top: 0px; background-color: white;"
        class="pt-16"
      >
        <fab-button
          :styleButton="
            'position: absolute; z-index: 1; left: 25px; top: 15px;'
          "
          @click="trafficMode()"
          dark
          right
          xsmall
          :icon="'mdi-traffic-light'"
          :tooltipSpan="trafficStatus ? 'Hide Traffic' : 'Show Traffic'"
        ></fab-button>
        <fab-button
          :styleButton="
            'position: absolute; z-index: 1; left: 65px; top: 15px;'
          "
          @click="focusOnVehicles()"
          dark
          right
          xsmall
          :icon="'mdi-map-search'"
          :tooltipSpan="'Focus on Vehicles'"
        ></fab-button>
        <search-field
          style="height: 100%; width:90%; margin: 0 auto;"
          @search="search"
          :title="'Search Vehicle'"
        ></search-field>
      </div>
      <div class="box">
        <div class="vehicle-column pt-0">
          <v-card elevation="0" style="height: 100%; width:100%;">
            <v-card-title style="padding-top: 100px;"> </v-card-title>
            <v-divider></v-divider>
            <v-container v-if="loadingList">
              <v-row>
                <v-col cols="12">
                  <center>
                    <span class="primary--text">Loading...</span>
                  </center>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-card
              v-for="(item, i) in vehiclesArray"
              :key="i"
              class="elevation-0"
            >
              <v-card-text class="pa-0">
                <v-container
                  @click="clickMarker(item.imei)"
                  fluid
                  class="py-1 vehiclelist"
                >
                  <v-row>
                    <v-col cols="12" sm="7" md="7" lg="7" xl="7">
                      <div class="word-break">
                        <strong>{{ `${item.plate_no.toUpperCase()}` }}</strong>
                      </div>

                      <div class="word-break">
                        <strong>Last Seen:</strong>
                        {{
                          $dateFormat(item.datetimestamp, "YYYY-MM-DD HH:mm:ss")
                        }}
                      </div>
                      <div
                        v-if="$store.state.company_settings.dashboard_location"
                        class="word-break"
                      >
                        <strong>Location:</strong>
                        {{
                            item.location.toUpperCase(),
                        }}
                      </div>
                      <div
                        v-if="$store.state.company_settings.dashboard_speed"
                        class="word-break"
                      >
                        <strong>Speed:</strong>
                        {{
                            `${item.speed} kph`,
                        }}
                      </div>
                      <div
                        v-if="
                          $store.state.company_settings.dashboard_temperature
                        "
                        class="word-break"
                      >
                        <strong>Temperature:</strong>
                        {{
                            `${item.temperature ? item.temperature : 0} °C`,
                        }}
                      </div>
                      <div
                        v-if="
                          $store.state.company_settings.dashboard_gps_battery
                        "
                        class="word-break"
                      >
                        <strong>GPS Battery:</strong>
                        {{ `${item.voltage} %` }}
                        <!-- item.battery_level, -->
                      </div>
                      <!-- This is the action button for item -->
                      <div
                        v-if="
                          $store.state.company_settings.dashboard_snailtrail
                        "
                        class="word-break"
                      >
                        <snailtrail-button
                          @click="openViewSnailtrail(item)"
                        ></snailtrail-button>
                      </div>
                      <!-- end of action button for item -->
                    </v-col>
                    <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                      <v-chip
                        x-small
                        class="float-right white--text pa-2"
                        style="margin-top: 5px;"
                        :color="item.status.color"
                        >{{ item.status.code }}</v-chip
                      >
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
      </div>
      <div class="gmap-column">
        <official-map id="map" ref="map"></official-map>
      </div>
    </div>
    <!-- end of side panel -->

    <!----- Here is the View Snailtrail ----------->

    <full-width-dialog
      :dialog="viewSnailTrail"
      :title="'Snailtrail - ' + snailtrailParams.plate_no"
      :color="'blue'"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue'"
      dark
      @closeDialog="closeViewSnailtrailDialog"
    >
      <v-card class="elevation-0">
        <v-container fluid class="py-0">
          <v-row>
            <v-container fluid>
              <v-row>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <v-data-table
                    dense
                    :headers="snailtrailHeaders"
                    :items="snailtrailItems"
                    class="elevation-0 scrolling-wrapper-two"
                    disable-pagination
                    hide-default-footer
                    :server-items-length="snailtrailItems.length"
                  >
                    <template
                      v-if="snailtrailItems.length === 0"
                      v-slot:no-data
                    >
                      <v-container>
                        <v-row>
                          <v-col md="6" offset-md="3">
                            <span class="title font-weight-bold"
                              >NO DATA AVAILABLE.</span
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-else v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="(obj, i) in items"
                          :key="i"
                          @click="clickSnailtrail(i)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            {{ snailtrailItems.length - i }}
                          </td>
                          <td class="text-no-wrap text-center text-uppercase">
                            {{ $dateFormat(obj.datetimestamp, "YYYY-MM-DD") }}
                          </td>
                          <td class="text-no-wrap text-center text-uppercase">
                            {{ $dateFormat(obj.datetimestamp, "HH:mm:ss") }}
                          </td>
                          <td class="text-no-wrap text-right text-uppercase">
                            {{ obj.speed }}
                          </td>
                          <td
                            :class="[
                              'text-center text-no-wrap text-uppercase',
                              obj.speed !== 0 ? 'green--text' : 'red--text',
                            ]"
                          >
                            {{ obj.speed !== 0 ? "RUNNING" : "STOPPED" }}
                          </td>
                          <td class="text-center text-no-wrap">
                            {{ obj.engine_status !== 0 ? "ON" : "OFF" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col sm="12" md="6" lg="6" xl="6" style="height: 92v;">
                  <official-map ref="snailtrailMap"></official-map>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card>
    </full-width-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/SubCompanyServices";
import CompanyServices from "@/services/CompanyServices";
import moment from "moment";

export default {
  data: () => {
    return {
      title: "Dashboard",
      data: {},
      queryParams: {},
      typeOfView: "map-view",
      viewTypeItems: [
        {
          text: "Dashboard",
          value: "dashboard",
        },
        {
          text: "Map View",
          value: "map-view",
        },
      ],
      boundsPolygon: [],
      show: true,
      loadingList: false,
      vehiclesArray: [],
      trafficStatus: false,
      enableAutoFocus: false,
      snailtrailHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Date", sortable: false, align: "center" },
        { text: "Time", sortable: false, align: "center" },
        { text: "Speed", sortable: false, align: "center" },
        { text: "Status", sortable: false, align: "center" },
        { text: "Engine", sortable: false, align: "center" },
      ],
      snailtrailItems: [],
      viewSnailTrail: false,
      fullWidthDialogLoading: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      timeFrom: null,
      menuDateFrom: false,
      dateTo: new Date().toISOString().substr(0, 10),
      timeTo: null,
      snailtrailParams: {
        plate_no: null,
        date_time_from: null,
        date_time_to: null,
      },
      interval: null,
    };
  },
  // created: async function() {
  //   if (this.$store.state.token !== null) {
  //     await this.getData();
  //     await this.sockets.subscribe("currentTransaction", async (data) => {
  //       if (data.sub_company_id == this.$store.state.subcompany.id) {
  //         let vehiclesArray = this.$store.state.vehicle_array;

  //         const index = vehiclesArray.findIndex((item) => {
  //           return item.imei === data.imei;
  //         });

  //         if (index > -1) {
  //           vehiclesArray.splice(index, 1);
  //           vehiclesArray.push(data);
  //         } else {
  //           vehiclesArray.push(data);
  //         }

  //         let finalVehiclesArray = vehiclesArray.filter((item) => {
  //           return item.sub_company_id == this.$store.state.subcompany.id;
  //         });

  //         this.$store.dispatch(
  //           "dispatchCurrentTransaction",
  //           finalVehiclesArray
  //         );

  //         this.boundsPolygon.length = 0;

  //         const vehiclesArrayMap = [];

  //         // const storedVehicles = this.$store.state.vehicle_array;

  //         this.$store.state.vehicle_array.forEach(async (obj) => {
  //           this.boundsPolygon.push({ lat: obj.lat, lng: obj.lng });

  //           vehiclesArrayMap.push({
  //             id: obj.imei,
  //             coordinates: { lat: obj.lat, lng: obj.lng },
  //             previous_coordinates: {
  //               lat: obj.previous_lat,
  //               lng: obj.previous_lng,
  //             },
  //             second_previous_coordinates: {
  //               lat: obj.second_previous_lat,
  //               lng: obj.second_previous_lng,
  //             },
  //             content: `<span class='font-weight-bold'>${obj.plate_no}</span>
  //            <hr> <span class='font-weight-bold'>Date/Time:</span> ${
  //              obj.datetimestamp
  //            }
  //            <br> <span class='font-weight-bold'>Location:</span> ${
  //              obj.location
  //            }
  //            <br> <span class='font-weight-bold'>Temperature:</span> ${obj.temperature +
  //              " °C"}
  //              <br> <span class='font-weight-bold'>Battery Level:</span> ${obj.voltage +
  //                " %"}`,
  //             path:
  //               obj.type_of_vehicle == "Bus"
  //                 ? this.$vehicleType(obj.type_of_vehicle)
  //                 : obj.status.status === 2
  //                 ? require("../../assets/marker_icons/map/mv_eon_running.svg")
  //                 : obj.status.status === 6
  //                 ? require("../../assets/marker_icons/map/mv_eon_stopped.svg")
  //                 : obj.status.status === 5
  //                 ? require("../../assets/marker_icons/map/mv_deco.svg")
  //                 : obj.status.status === 4
  //                 ? require("../../assets/marker_icons/map/mv_gps_for_service.svg")
  //                 : obj.status.status === 3
  //                 ? require("../../assets/marker_icons/map/mv_gps_delay.svg")
  //                 : obj.status.status === 8
  //                 ? require("../../assets/marker_icons/map/mv_eoff.svg")
  //                 : obj.status.status === 9
  //                 ? require("../../assets/marker_icons/map/mv_eon.svg")
  //                 : obj.status.status === 7
  //                 ? require("../../assets/marker_icons/map/mv_gps_sleep_mode.svg")
  //                 : "",
  //             label_content: obj.plate_no,
  //             markerSize: { x: 40, y: 35 },
  //             color:
  //               obj.status.status === 2
  //                 ? "#008B0D"
  //                 : obj.status.status === 6
  //                 ? "#940000"
  //                 : obj.status.status === 5
  //                 ? "#DF3A70"
  //                 : obj.status.status === 4
  //                 ? "#606060"
  //                 : obj.status.status === 3
  //                 ? "#F89820"
  //                 : obj.status.status === 8
  //                 ? "#000101"
  //                 : obj.status.status === 9
  //                 ? "#009688"
  //                 : obj.status.status === 7
  //                 ? "#111111"
  //                 : "",
  //             type_of_vehicle: obj.type_of_vehicle,
  //             // label_class:
  //             //   data.status.status === 2
  //             //     ? "hasRunninglabels"
  //             //     : data.status.status === 6
  //             //     ? "hasStoppedlabels"
  //             //     : data.status.status === 5
  //             //     ? "hasDecommissionedlabels"
  //             //     : data.status.status === 4
  //             //     ? "hasGPSForServicelabels"
  //             //     : data.status.status === 3
  //             //     ? "hasDelaylabels"
  //             //     : data.status.status === 8
  //             //     ? "hasEngineOfflabels"
  //             //     : data.status.status === 9
  //             //     ? "hasEngineOnlabels"
  //             //     : data.status.status === 7
  //             //     ? "hasEngineOfflabels"
  //             //     : "",
  //           });
  //         });

  //         this.$refs.map.removeMarkers();
  //         this.initMap(vehiclesArrayMap);

  //         let vehiclesList = this.$store.state.vehicle_array;
  //         const returnedVehicleList = await Promise.all(
  //           vehiclesList.map(async (item, i) => {
  //             return {
  //               index: i,
  //               id: item.id,
  //               company_id: item.company_id,
  //               datetimestamp: item.datetimestamp,
  //               dispatch_count: item.dispatch_count,
  //               imei: item.imei,
  //               lat: item.lat,
  //               lng: item.lng,
  //               location: item.location,
  //               plate_no: item.plate_no,
  //               speed: item.speed,
  //               temperature: item.temperature,
  //               fuel: item.fuel,
  //               voltage: item.voltage,
  //               vehicle_status: item.vehicle_status,
  //               status: item.status,
  //             };
  //           })
  //         );

  //         this.vehiclesArray = returnedVehicleList.sort((b, a) => {
  //           if (a.plate_no < b.plate_no) {
  //             return -1;
  //           }
  //           if (a.plate_no > b.plate_no) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //       }
  //     });

  //     const outlets = await this.setOutlets();

  //     await this.$refs.map.initializeMap();

  //     const polygonsCoordinate = [];

  //     outlets.forEach(async (item) => {
  //       let contentString = ``;

  //       contentString = `${item.name.toUpperCase()}`;

  //       polygonsCoordinate.push({
  //         coordinates: item.coordinates,
  //         content: contentString,
  //         id: item.id,
  //         image_url:
  //           item.zone_type_stats.type_geozone == 1
  //             ? require("../../assets/outlet-markers/outlet_geozone.svg")
  //             : item.zone_type_stats.type_geofence == 1
  //             ? require("../../assets/outlet-markers/outlet_geofence.svg")
  //             : item.zone_type_stats.type_hotspot == 1
  //             ? require("../../assets/outlet-markers/outlet_hotspot.svg")
  //             : "",
  //       });

  //       const color =
  //         item.zone_type_stats.type_geozone == 1
  //           ? "#008B0D"
  //           : item.zone_type_stats.type_geofence == 1
  //           ? "#FF9800"
  //           : item.zone_type_stats.type_hotspot == 1
  //           ? "#940000"
  //           : "";

  //       await this.$refs.map.plotPolygons(item.coordinates, color);
  //     });

  //     await this.$refs.map.boundsMarkerOutlet(polygonsCoordinate);

  //     this.interval = await setInterval(() => {
  //       this.getDataMap();
  //     }, 1000 * 60);
  //   } else {
  //     this.$router.push({
  //       path: "/",
  //     });

  //     this.$store.dispatch("clearStore");
  //   }
  // },
  created: async function() {
    if (this.$store.state.token !== null) {
      this.$store.dispatch("startLoading");

      const dashboardData = await this.getDataMap();

      const filteredData = dashboardData.data.filter((item) => {
        return item;
      });

      let finalVehiclesArray = filteredData.filter((item) => {
        return item.sub_company_id == this.$store.state.subcompany.id;
      });

      this.$store.dispatch("dispatchCurrentTransaction", finalVehiclesArray);

      this.$store.dispatch("endLoading");

      await this.$refs.map.initializeMap();

      this.boundsPolygon.length = 0;

      const vehiclesArrayMap = [];

      this.$store.state.vehicle_array.forEach(async (obj) => {
        this.boundsPolygon.push({ lat: obj.lat, lng: obj.lng });

        vehiclesArrayMap.push({
          id: obj.imei,
          coordinates: { lat: obj.lat, lng: obj.lng },
          previous_coordinates: {
            lat: obj.previous_lat,
            lng: obj.previous_lng,
          },
          second_previous_coordinates: {
            lat: obj.second_previous_lat,
            lng: obj.second_previous_lng,
          },
          content: `<span class='font-weight-bold'>${obj.plate_no}</span>
             <hr> <span class='font-weight-bold'>Date/Time:</span> ${
               obj.datetimestamp
             }
             <br> <span class='font-weight-bold'>Location:</span> ${
               obj.location
             }
             <br> <span class='font-weight-bold'>Temperature:</span> ${obj.temperature +
               " °C"}
               <br> <span class='font-weight-bold'>Battery Level:</span> ${obj.voltage +
                 " %"}`,
          path:
            obj.type_of_vehicle == "Bus" ||
            obj.type_of_vehicle == "Truck/Close Van" ||
            obj.type_of_vehicle == "Sedan" ||
            obj.type_of_vehicle == "Jeep" ||
            obj.type_of_vehicle == "SUV/Van" ||
            obj.type_of_vehicle == "Trailer Truck"
              ? this.$vehicleType(obj.type_of_vehicle)
              : obj.status.status === 2
              ? require("../../assets/marker_icons/map/mv_eon_running.svg")
              : obj.status.status === 6
              ? require("../../assets/marker_icons/map/mv_eon_stopped.svg")
              : obj.status.status === 5
              ? require("../../assets/marker_icons/map/mv_deco.svg")
              : obj.status.status === 4
              ? require("../../assets/marker_icons/map/mv_gps_for_service.svg")
              : obj.status.status === 3
              ? require("../../assets/marker_icons/map/mv_gps_delay.svg")
              : obj.status.status === 8
              ? require("../../assets/marker_icons/map/mv_eoff.svg")
              : obj.status.status === 9
              ? require("../../assets/marker_icons/map/mv_eon.svg")
              : obj.status.status === 7
              ? require("../../assets/marker_icons/map/mv_gps_sleep_mode.svg")
              : "",
          label_content: obj.plate_no,
          markerSize: { x: 40, y: 35 },
          color:
            obj.status.status === 2
              ? "#008B0D"
              : obj.status.status === 6
              ? "#940000"
              : obj.status.status === 5
              ? "#DF3A70"
              : obj.status.status === 4
              ? "#606060"
              : obj.status.status === 3
              ? "#F89820"
              : obj.status.status === 8
              ? "#000101"
              : obj.status.status === 9
              ? "#009688"
              : obj.status.status === 7
              ? "#000000"
              : "",
          type_of_vehicle: obj.type_of_vehicle,
        });
      });

      await this.$refs.map.boundsOfAllMarkers(this.boundsPolygon);
      this.$refs.map.removeMarkers();
      this.initMap(vehiclesArrayMap);

      let vehiclesList = this.$store.state.vehicle_array;
      const returnedVehicleList = await Promise.all(
        vehiclesList.map(async (item, i) => {
          return {
            index: i,
            id: item.id,
            company_id: item.company_id,
            datetimestamp: item.datetimestamp,
            dispatch_count: item.dispatch_count,
            imei: item.imei,
            lat: item.lat,
            lng: item.lng,
            location: item.location,
            plate_no: item.plate_no,
            speed: item.speed,
            temperature: item.temperature,
            fuel: item.fuel,
            voltage: item.voltage,
            vehicle_status: item.vehicle_status,
            status: item.status,
          };
        })
      );

      this.vehiclesArray = returnedVehicleList.sort((b, a) => {
        // if (a.plate_no < b.plate_no) {
        //   return -1;
        // }
        // if (a.plate_no > b.plate_no) {
        //   return 1;
        // }
        // return 0;

        return new Date(a.datetimestamp) - new Date(b.datetimestamp);
      });

      const outlets = await this.setOutlets();

      const polygonsCoordinate = [];

      outlets.forEach(async (item) => {
        let contentString = ``;

        contentString = `${item.name.toUpperCase()}`;

        polygonsCoordinate.push({
          coordinates: item.coordinates,
          content: contentString,
          id: item.id,
          image_url:
            item.zone_type_stats.type_geozone == 1
              ? require("../../assets/outlet-markers/outlet_geozone.svg")
              : item.zone_type_stats.type_geofence == 1
              ? require("../../assets/outlet-markers/outlet_geofence.svg")
              : item.zone_type_stats.type_hotspot == 1
              ? require("../../assets/outlet-markers/outlet_hotspot.svg")
              : "",
        });

        const color =
          item.zone_type_stats.type_geozone == 1
            ? "#008B0D"
            : item.zone_type_stats.type_geofence == 1
            ? "#FF9800"
            : item.zone_type_stats.type_hotspot == 1
            ? "#940000"
            : "";

        await this.$refs.map.plotPolygons(item.coordinates, color);
      });

      await this.$refs.map.boundsMarkerOutlet(polygonsCoordinate);

      this.interval = await setInterval(async () => {
        const dashboardData = await this.getDataMap();

        const filteredData = dashboardData.data.filter((item) => {
          return item;
        });

        let finalVehiclesArray = filteredData.filter((item) => {
          return item.sub_company_id == this.$store.state.subcompany.id;
        });

        this.$store.dispatch("dispatchCurrentTransaction", finalVehiclesArray);

        this.boundsPolygon.length = 0;

        const vehiclesArrayMap = [];

        this.$store.state.vehicle_array.forEach(async (obj) => {
          this.boundsPolygon.push({ lat: obj.lat, lng: obj.lng });

          vehiclesArrayMap.push({
            id: obj.imei,
            coordinates: { lat: obj.lat, lng: obj.lng },
            previous_coordinates: {
              lat: obj.previous_lat,
              lng: obj.previous_lng,
            },
            second_previous_coordinates: {
              lat: obj.second_previous_lat,
              lng: obj.second_previous_lng,
            },
            content: `<span class='font-weight-bold'>${obj.plate_no}</span>
             <hr> <span class='font-weight-bold'>Date/Time:</span> ${
               obj.datetimestamp
             }
             <br> <span class='font-weight-bold'>Location:</span> ${
               obj.location
             }
             <br> <span class='font-weight-bold'>Temperature:</span> ${obj.temperature +
               " °C"}
               <br> <span class='font-weight-bold'>Battery Level:</span> ${obj.voltage +
                 " %"}`,
            path:
              obj.type_of_vehicle == "Bus" ||
              obj.type_of_vehicle == "Truck/Close Van" ||
              obj.type_of_vehicle == "Sedan" ||
              obj.type_of_vehicle == "Jeep" ||
              obj.type_of_vehicle == "SUV/Van" ||
              obj.type_of_vehicle == "Trailer Truck"
                ? this.$vehicleType(obj.type_of_vehicle)
                : obj.status.status === 2
                ? require("../../assets/marker_icons/map/mv_eon_running.svg")
                : obj.status.status === 6
                ? require("../../assets/marker_icons/map/mv_eon_stopped.svg")
                : obj.status.status === 5
                ? require("../../assets/marker_icons/map/mv_deco.svg")
                : obj.status.status === 4
                ? require("../../assets/marker_icons/map/mv_gps_for_service.svg")
                : obj.status.status === 3
                ? require("../../assets/marker_icons/map/mv_gps_delay.svg")
                : obj.status.status === 8
                ? require("../../assets/marker_icons/map/mv_eoff.svg")
                : obj.status.status === 9
                ? require("../../assets/marker_icons/map/mv_eon.svg")
                : obj.status.status === 7
                ? require("../../assets/marker_icons/map/mv_gps_sleep_mode.svg")
                : "",
            label_content: obj.plate_no,
            markerSize: { x: 40, y: 35 },
            color:
              obj.status.status === 2
                ? "#008B0D"
                : obj.status.status === 6
                ? "#940000"
                : obj.status.status === 5
                ? "#DF3A70"
                : obj.status.status === 4
                ? "#606060"
                : obj.status.status === 3
                ? "#F89820"
                : obj.status.status === 8
                ? "#000101"
                : obj.status.status === 9
                ? "#009688"
                : obj.status.status === 7
                ? "#000000"
                : "",
            type_of_vehicle: obj.type_of_vehicle,
          });
        });

        this.$refs.map.removeMarkers();
        this.initMap(vehiclesArrayMap);

        let vehiclesList = this.$store.state.vehicle_array;
        const returnedVehicleList = await Promise.all(
          vehiclesList.map(async (item, i) => {
            return {
              index: i,
              id: item.id,
              company_id: item.company_id,
              datetimestamp: item.datetimestamp,
              dispatch_count: item.dispatch_count,
              imei: item.imei,
              lat: item.lat,
              lng: item.lng,
              location: item.location,
              plate_no: item.plate_no,
              speed: item.speed,
              temperature: item.temperature,
              fuel: item.fuel,
              voltage: item.voltage,
              vehicle_status: item.vehicle_status,
              status: item.status,
            };
          })
        );

        this.vehiclesArray = returnedVehicleList.sort((b, a) => {
          // if (a.plate_no < b.plate_no) {
          //   return -1;
          // }
          // if (a.plate_no > b.plate_no) {
          //   return 1;
          // }
          // return 0;

          return new Date(a.datetimestamp) - new Date(b.datetimestamp);
        });
      }, 1000 * 60);
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  watch: {
    typeOfView: async function() {
      if (this.typeOfView === "dashboard") {
        this.$router.push({
          path: "/c/home",
        });
      }

      if (this.typeOfView === "map-view") {
        this.$router.push({
          path: "/c/dashboard/map-view",
        });
      }
    },
    boundsPolygon: async function() {
      if (this.enableAutoFocus) {
        if (this.boundsPolygon.length !== 0) {
          await this.$refs.map.boundsOfAllMarkers(this.boundsPolygon);
        }
      }
    },
  },
  methods: {
    async viewType() {
      this.$router.push({
        path: "/c/s/home",
      });
    },
    async search(data) {
      let search = data.toUpperCase().trim();

      let vehicles = null;

      if (search) {
        vehicles = this.$store.state.vehicle_array.filter(
          (item) => item.plate_no.toUpperCase().indexOf(search) > -1
        );
      } else {
        vehicles = this.$store.state.vehicle_array;
      }

      this.vehiclesArray = vehicles;
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;
      this.queryParams.sub_company_id = this.$store.state.subcompany.id;
      this.queryParams.user_designation = this.$store.state.employee.user_designation;

      try {
        await Services.getDashboard(this.queryParams);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    // async getDataMap() {
    //   this.queryParams.company_id = this.$store.state.company.id;
    //   this.queryParams.sub_company_id = this.$store.state.subcompany.id;
    //   this.queryParams.user_designation = this.$store.state.employee.user_designation;

    //   try {
    //     await Services.getDashboard(this.queryParams);
    //   } catch (error) {
    //     if (!error.response) {
    //       this.$infoBar({
    //         type: "error",
    //         text: "No network or server connection",
    //       });
    //     }
    //   }
    // },
    async getDataMap() {
      let data = null;

      this.queryParams.sub_company_id = this.$store.state.subcompany.id;

      try {
        const response = await Services.getDashboardMapApi(this.queryParams);

        data = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async initMap(array) {
      if (array.length !== 0) {
        await this.$refs.map.insertMultipleMarker(array);
        // await this.$refs.map.removeClusterMarker();
        // await this.$refs.map.markerCluster();
      }
    },
    async openVehicleList() {
      this.show = true;

      this.loadingList = true;

      this.loadingList = false;
    },
    async hideVehicleList() {
      this.show = false;
    },
    async openViewSnailtrail(obj) {
      let timeToSubtract = moment.duration("02:00:00");

      let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

      let returnCurrentDate = moment(currentDate);

      returnCurrentDate.subtract(timeToSubtract);

      const date_from = this.$dateFormat(obj.datetimestamp, "YYYY-MM-DD");

      const date_to = this.$dateFormat(obj.datetimestamp, "YYYY-MM-DD");

      const time_from = returnCurrentDate.format("HH:mm:ss");

      const time_to = moment().format("HH:mm");

      this.snailtrailParams.vehicle_id = obj.id;

      this.snailtrailParams.plate_no = obj.plate_no;

      this.snailtrailParams.date_time_from = date_from + " " + time_from;

      this.snailtrailParams.date_time_to = date_to + " " + time_to;

      this.fullWidthDialogLoading = true;

      this.viewSnailTrail = true;

      const data = await this.setSnailtrail();

      this.snailtrailItems = await Promise.all(
        data.map((item) => {
          return {
            plate_no: obj.plate_no,
            lat: item.lat,
            lng: item.lng,
            datetimestamp: item.datetimestamp,
            speed: item.speed,
            engine_status: item.engine_status,
            location: item.location,
          };
        })
      );

      if (data) {
        this.fullWidthDialogLoading = true;
        this.viewSnailTrailDialog = true;
        this.$store.dispatch("dispatchSnailtrail", data);
        // // this.data.totalKmRun = data.km_run;
        this.data.plate_no = obj.plate_no;
        const boundsCoordinate = [];
        setTimeout(async () => {
          const snailtrail = await Promise.all(
            data.map((item, index) => {
              boundsCoordinate.push({
                coordinates: {
                  lat: item.lat,
                  lng: item.lng,
                },
              });
              return {
                date: this.$dateFormat(item.datetimestamp, "YYYY-MM-DD"),
                time: this.$dateFormat(item.datetimestamp, "HH:mm:ss"),
                lat: item.lat,
                lng: item.lng,
                location: item.location,
                id: index,
                remarks: item.speed !== 0 ? "RUNNING" : "STOPPED",
                // remarks: item.moving_status.toLowerCase(),
                // status: item.moving_status.toLowerCase(),
                speed: item.speed,
                engine_status: item.engine_status,
                content: `No: ${this.$store.state.snailtrail.length -
                  index}<br>Location: ${
                  item.location ? item.location : ""
                }<br>Date: ${this.$dateFormat(
                  item.datetimestamp,
                  "YYYY-MM-DD"
                )}<br>Time: ${this.$dateFormat(
                  item.datetimestamp,
                  "HH:mm:ss"
                )}<br>Speed: ${item.speed + " " + "kph"}<br>Status: ${
                  item.speed !== 0 ? "RUNNING" : "STOPPED"
                }<br>Engine: ${item.engine_status !== 0 ? "ON" : "OFF"}<br>`,
              };
            })
          );
          this.$refs.snailtrailMap.initializeMap();
          this.$refs.snailtrailMap.viewSnailTrail(snailtrail);
          // this.$refs.snailtrailMap.boundsSnailtrailMarker(snailtrail);
          this.$refs.snailtrailMap.boundsMarker(boundsCoordinate);
        }, 1000);
        setTimeout(() => {
          this.fullWidthDialogLoading = false;
        }, 600);
      }
    },
    closeViewSnailtrailDialog() {
      this.viewSnailTrail = false;
    },
    // async openViewSnailtrail(data) {
    //   let timeToSubtract = moment.duration("02:00:00");

    //   let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

    //   let returnCurrentDate = moment(currentDate);

    //   returnCurrentDate.subtract(timeToSubtract);

    //   const vehicle_id = data.id;
    //   const vehicle_plate_no = data.plate_no;
    //   const date_from = this.$dateFormat(data.datetimestamp, "YYYY-MM-DD");
    //   const date_to = this.$dateFormat(data.datetimestamp, "YYYY-MM-DD");
    //   const time_from = returnCurrentDate.format("HH:mm:ss");
    //   const time_to = moment().format("HH:mm");

    //   let mapForm = document.createElement("form");

    //   mapForm.target = "tracklist-map";
    //   mapForm.method = "POST"; // or "post" if appropriate
    //   mapForm.action = process.env.VUE_APP_TRACKLIST_URL;

    //   let mapInputVehicleId = document.createElement("input");
    //   mapInputVehicleId.type = "hidden";
    //   mapInputVehicleId.name = "vehicle_id";
    //   mapInputVehicleId.value = vehicle_id;

    //   mapForm.appendChild(mapInputVehicleId);

    //   let mapInputPlateNo = document.createElement("input");
    //   mapInputPlateNo.type = "hidden";
    //   mapInputPlateNo.name = "plate_no";
    //   mapInputPlateNo.value = vehicle_plate_no;

    //   mapForm.appendChild(mapInputPlateNo);

    //   let mapInputDateFrom = document.createElement("input");
    //   mapInputDateFrom.type = "hidden";
    //   mapInputDateFrom.name = "date_from";
    //   mapInputDateFrom.value = date_from;

    //   mapForm.appendChild(mapInputDateFrom);

    //   let mapInputDateTo = document.createElement("input");
    //   mapInputDateTo.type = "hidden";
    //   mapInputDateTo.name = "date_to";
    //   mapInputDateTo.value = date_to;

    //   mapForm.appendChild(mapInputDateTo);

    //   let mapInputTimeFrom = document.createElement("input");
    //   mapInputTimeFrom.type = "hidden";
    //   mapInputTimeFrom.name = "time_from";
    //   mapInputTimeFrom.value = time_from;

    //   mapForm.appendChild(mapInputTimeFrom);

    //   let mapInputTimeTo = document.createElement("input");
    //   mapInputTimeTo.type = "hidden";
    //   mapInputTimeTo.name = "time_to";
    //   mapInputTimeTo.value = time_to;

    //   mapForm.appendChild(mapInputTimeTo);

    //   let mapInputToken = document.createElement("input");
    //   mapInputToken.type = "hidden";
    //   mapInputToken.name = "token";
    //   mapInputToken.value = this.$store.state.token;

    //   mapForm.appendChild(mapInputToken);

    //   document.body.appendChild(mapForm);

    //   window.open("", "tracklist-map", "top=70,left=250,width=1600,height=800");

    //   mapForm.submit();
    // },
    async clickMarker(id) {
      await this.$refs.map.clickVehicle(id);
    },
    async clickSnailtrail(id) {
      await this.$refs.snailtrailMap.clickSnailtrail(id);
    },
    async trafficMode() {
      this.trafficStatus = !this.trafficStatus;

      await this.$refs.map.trafficMode(this.trafficStatus);
    },
    async focusOnVehicles() {
      if (this.boundsPolygon.length !== 0) {
        await this.$refs.map.boundsOfAllMarkers(this.boundsPolygon);
      }
    },
    async setSnailtrail() {
      let data = null;

      try {
        const response = await CompanyServices.getSnailtrail(
          this.snailtrailParams
        );
        // const response = await Services.getTrackingSnailtrail(
        //   this.snailtrailParams
        // );
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        } else {
          if (error.response.status === 404) {
            this.$infoBar({
              type: "error",
              text: this.titleCase(error.response.data.message),
            });
          }
        }
      }
      return data;
    },
    async setOutlets() {
      let data = null;

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        // Query Data
        let response = (await Services.getOutlets(this.queryParams)).data;

        data = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        }
      }

      return data;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
  async beforeDestroy() {
    await clearInterval(this.interval);
    // await this.$socket.emit("clearInterval", true);
    // this.sockets.unsubscribe("currentTransaction");
    // await Services.disconnectSocketConnection();
    this.$store.dispatch("dispatchCurrentTransaction", []);
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-all;
  width: 32vh;
  font-size: 70%;
}
.box {
  height: calc(100vh - 50px);
  width: 20%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.gmap-column {
  /* box-sizing: border-box;
  border-style: groove; */
  width: 80%;
}
.vehicle-column {
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.row::after {
  box-sizing: border-box;
  content: "";
  clear: both;
  height: 100%;
}
.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.vehiclelist:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.vehicle-list-card {
  width: 400px;
}

input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Second */
input[type="time"]::-webkit-datetime-edit-second-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #b71c1c;
  border-radius: 15%;
  color: #fff;
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

@media (max-width: 360px) and (max-height: 640px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 411px) and (max-height: 731px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 411px) and (max-height: 823px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .vehicle-list-card {
    max-width: 90%;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media only screen and (min-width: 1100px) {
  .scrolling-wrapper {
    height: 60vh;
  }
}

@media only screen and (min-width: 1200px) {
  .scrolling-wrapper {
    height: 63vh;
  }
}

@media only screen and (min-width: 1400px) {
  .scrolling-wrapper {
    height: 67vh;
  }
}

@media only screen and (min-width: 1600px) {
  .scrolling-wrapper {
    height: 70vh;
  }
}

@media only screen and (min-width: 1700px) {
  .scrolling-wrapper {
    height: 72vh;
  }
}

@media only screen and (min-width: 1900px) {
  .scrolling-wrapper {
    height: 75vh;
  }
}

@media only screen and (min-width: 2500px) {
  .scrolling-wrapper {
    height: 80vh;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.scrolling-wrapper-two {
  overflow-x: hidden;
  height: 92vh;
  overflow-y: scroll;
  white-space: nowrap;
}

@media (max-width: 360px) and (max-height: 640px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 411px) and (max-height: 731px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 411px) and (max-height: 823px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .heightMap {
    height: 250px;
  }
}
</style>
